import React from 'react';
import { Link } from 'react-router-dom';
import './front.css';
import logo from 'spiral.svg';
import Header from 'layout/header';

const FrontPage = () => (
  <div className="dockable-header">
    <Header />
    <div className="App">
        <header className="App-header">
          <h1 style={ { fontFamily: 'MuseoModerno' } }>
            <img style={ { margin: '-33px 0' } }  src={logo} height="100px" className="App-logo" alt="logo" />
            <span>
              Lerianu
            </span>
          </h1>
          AI-Powered Solutions
          <div className="header-bumper">
            <div>
              Forecasted Queues
            </div>
          </div>
        </header>
        <h2>Schedule Generation</h2>
        <section>
          <h3>Round Robin League</h3>
          <ul>
            <li>League Details</li>
            <li>List of Teams</li>
            <li>Schedule Times</li>
            <li>Game Locations</li>
          </ul>
          <Link to="demo/league">Try it now</Link>
        </section>
        <section>
          <h3>Maximization of Daily Task Completion by Task Type</h3>
          <ul>
            <li>List of Team Members</li>
            <li>List of Task Types/Workareas/Queues/Buckets</li>
            <li>Daily forecast of Task counts by Task Type by day</li>
          </ul>
        </section>
      </div>
  </div>
);

export default FrontPage;
