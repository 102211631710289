import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'spiral.svg';
import { useScrollPosition } from 'react-use-scroll-position'

const MENU_VISIBILE_Y_THRESHOLD = 380;

const Header = () => {
    const { y } = useScrollPosition();
    const hideMenu = MENU_VISIBILE_Y_THRESHOLD > y;
  
    return (
        <header className={ `floating-app-header ${ hideMenu && 'docked' }` }>
            <div style={ { display: 'flex', padding: '0 1rem' } }>
                <Link to="/">
                    <img style={ { margin: '5px 0 0 5px' } }  src={logo} height="40px" className="App-logo" alt="logo" />
                </Link>
                <span style={ { fontFamily: 'MuseoModerno', fontSize: '1.4rem', margin: 'auto .5rem', fontWeight: 400 } }>
                    Lerianu
                </span>
            </div>
        </header>);
}

export default Header;
