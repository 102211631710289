import React from 'react';
import {
  Switch,
  Route
} from "react-router-dom";
import 'index.css';
import DemoLeaguePage from 'page/demo-league';

const DemoRouter = () => (
    <Switch>
        <Route path="/demo/list">
            <div>LIST</div>
        </Route>
        <Route path="/demo/league">
            <DemoLeaguePage />
        </Route>
    </Switch>);

export default DemoRouter;
