import React, { useState } from 'react';
import { Header } from 'layout';
import InlineSubheader from 'layout/inline-subheader';
import DockingSubheader from 'layout/docking-subheader';
import DemoLeagueImageLeft from 'asset-image/placeholder1.png';

Number.prototype.clamp = function(min, max) {
    return Math.min(Math.max(this, min), max);
};

const adjustTeamNameArray = (currentArr, newLength) => {
    if (currentArr.length >= newLength) {
        return currentArr;
    }
    const entryCountMissing = newLength - currentArr.length;
    const addedNames = [...Array(entryCountMissing).keys()].map((o, i) => `Team ${i+currentArr.length+1}`);
    return [
        ...currentArr,
        ...addedNames
    ];
};

const DemoLeaguePage = () => {
    const [leagueNoun, setLeagueNoun] = useState('League');
    const [teamCount, setTeamCount] = useState(6);
    const [teamNoun, setTeamNoun] = useState('Teams');
    const [teamNames, setTeamNames] = useState(adjustTeamNameArray([], 6));

    function changeTeamCount(newCount) {
        setTeamNames(adjustTeamNameArray(teamNames, newCount));
        setTeamCount(newCount);
    }

    return (<div>
        <Header />
        <InlineSubheader>
            <h3 style={ { margin: 'auto', fontFamily: 'MuseoModerno' } }>
                Round Robin League Schedule
            </h3>
        </InlineSubheader>

        <div style={ { display: 'flex', padding: '2rem' } }>
            <div style={ { width: '40%', paddingRight: '2rem', maxWidth: '50%'  } }>
                <img style={ { width: '100%' } } src={ DemoLeagueImageLeft } />
            </div>
            <div style={ { flexGrow: 1 } }>
                <h2>
                    { `${leagueNoun} Schedule` }
                </h2>
                <p>Answer the questions, get a schedule</p>
            </div>
        </div>

        <div style={ { display: 'flex', padding: '2rem' } }>
            <div style={ { width: '40%', paddingRight: '2rem', maxWidth: '50%'  } }>
                <h3>
                    { `${leagueNoun} ${teamNoun}` }
                </h3>
                This <select value={ leagueNoun } onChange={ ({ target }) => setLeagueNoun(target.value) }>
                    <option value='League'>League</option>
                    <option value='Season'>Season</option>
                    <option value='Session'>Session</option>
                    <option value='Exhibition Weekend'>Exhibition Weekend</option>
                </select> will have <input type="number" min="5" max="50" size="1" value={ teamCount } onChange={ ({ target }) => { changeTeamCount(parseInt(target.value).clamp(5, 50)); } } /> <select value={ teamNoun } onChange={ ({ target }) => setTeamNoun(target.value) }>
                    <option value="Teams">Teams</option>
                    <option value="Participants">Participants</option>
                    <option value="Players">Players</option>
                    <option value="Guilds">Guilds</option>
                    <option value="Countries">Countries</option>
                    <option value="Clubs">Clubs</option>
                </select>.
            </div>
            <div style={ { flexGrow: 1 } }>
                {
                    [...Array(teamCount).keys()].map((o, i) => (
                        <div key={i}>
                            { i + 1 }
                            <input type='text' value={ teamNames[i] } onChange={ ({ target }) => setTeamNames(teamNames.map((x, y) => i === y ? target.value : x)) } />
                        </div>
                    ))
                }
            </div>
        </div>

        <div style={ { display: 'flex', padding: '2rem' } }>
            <div style={ { width: '40%', paddingRight: '2rem', maxWidth: '50%'  } }>
                <h3>Match Locations</h3>
                A <b>Match</b> occurs at a <b>Field</b>.<br />
                There are <b>3</b> Available.<br /><br /><br />
                ** If unlimited, how many can be going on at one time?
            </div>
            <div style={ { flexGrow: 1 } }>
                Field 1: <input type='text' /><br />
                Field 2: <input type='text' /><br />
                Field 3: <input type='text' /><br />
            </div>
        </div>

        <div style={ { display: 'flex', padding: '2rem' } }>
            <div style={ { width: '40%', paddingRight: '2rem', maxWidth: '50%'  } }>
                <h3>Match Days</h3>
                A Match may occur on:<br />
                <input type="checkbox" /> Sundays
                <input type="checkbox" /> Mondays
                <input type="checkbox" /> Tuesdays
                <input type="checkbox" /> Wednesdays
                <input type="checkbox" /> Thursdays
                <input type="checkbox" /> Fridays
                <input type="checkbox" checked /> Saturdays
            </div>
            <div style={ { flexGrow: 1 } }>
                <b>Saturday Field Availability</b>
                <ul>
                    <li>Field 1 Match Count: 2<br/>(+ Add Time Slot)<br />[1:00 PM]  [2:00 PM]</li>
                    <li>Field 2 Match Count: 1<br/>(+ Add Time Slot)<br />[1:00 PM]</li>
                    <li>Field 3 Match Count: 1<br/>(+ Add Time Slot)<br />[3:00 PM]</li>
                </ul>
                ** You will be able to make exceptions for specific dates later
            </div>
        </div>
        
        <div style={ { display: 'flex', padding: '2rem' } }>
            <div style={ { width: '40%', paddingRight: '2rem', maxWidth: '50%'  } }>
                <h3>League Length</h3>
                League play will begin on <b>July 8th, 2020</b>.<br />
                Each Team will play <b>6</b> times.<br/>
            </div>
            <div style={ { flexGrow: 1 } }>
                Season Match Count: <b>18</b><br />
                Weekly Match Count: <b>4</b><br />
                <br />
                Minimum Weeks: <b>5</b>
            </div>
        </div>

        <div style={ { display: 'flex', padding: '2rem' } }>
            <div style={ { width: '40%', paddingRight: '2rem', maxWidth: '50%'  } }>
                <h3>Fine Tuning</h3>
                <i>final step for development</i>
                <ul>
                    <li>Add or Remove Specific Timeslots for a Field</li>
                    <li>Provide Specific Timeslots When a Specific Team Can't Play Or Must Play</li>
                    <li>Make a Rule: 2 Specific Teams Can't Play At The Same Time (shared coach or player)</li>
                </ul>
            </div>
            <div style={ { flexGrow: 1 } }>
                <h3>Scheduling Rules</h3>
                <ul>
                    <li><input type="checkbox" checked /> Balance Home/Visitor Match Count</li>
                    <li><input type="checkbox" checked /> Balance Bye Days/Weeks</li>
                    <li><input type="checkbox" /> Do Not Allow Doubleheaders (2 games for 1 team in a day)</li>
                </ul>
            </div>
        </div>

        <div style={ { display: 'flex', padding: '2rem' } }>
            <div style={ { width: '40%', paddingRight: '2rem', maxWidth: '50%'  } }>
                <h3>General Information</h3>
                <i>optional</i><br />
                - League Name <input type='text' /><br />
                - Sport Name <input type='text' /><br />
                - Website  <input type='text' /><br />
                - Organized By <input type='text' /><br />
                - Organization Contact <input type='text' /><br />
            </div>
            <div style={ { flexGrow: 1 } }>
                You are not currently logged in. This is a publicly editable schedule unless you login and claim it!
            </div>
        </div>


        [GENERATE SCHEDULE]
    </div>);
}

export default DemoLeaguePage;
