import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import 'index.css';
import FrontPage from 'page/front/front';
import DemoRouter from './demo-router';
import ScrollToTop from 'util/scroll-to-top';


export default () => (<React.StrictMode><Router>
  <ScrollToTop />
  <Switch>
    <Route path="/" exact>
      <FrontPage />
    </Route>
    <Route path="/demo">
      <DemoRouter />
    </Route>
  </Switch>
</Router></React.StrictMode>);